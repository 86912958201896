export const CURRENCIES = {
	EUR: {
		symbol: '€',
		template: (amount) => `€${amount.toFixed(2)}`,
	},
	USD: {
		symbol: '$',
		template: (amount) => `$${amount.toFixed(2)}`,
	},
	AUD: {
		symbol: 'AU$',
		template: (amount) => `AU$${amount.toFixed(2)}`,
	},
	CAD: {
		symbol: 'CA$',
		template: (amount) => `CA$${amount.toFixed(2)}`,
	},
	NZD: {
		symbol: 'NZ$',
		template: (amount) => `NZ$${amount.toFixed(2)}`,
	},
	HKD: {
		symbol: 'HK$',
		template: (amount) => `HK$${amount.toFixed(2)}`,
	},
	MXN: {
		symbol: 'MX$',
		template: (amount) => `MX$${amount.toFixed(2)}`,
	},
	SGD: {
		symbol: 'S$',
		template: (amount) => `S$${amount.toFixed(2)}`,
	},
	BRL: {
		symbol: 'R$',
		template: (amount) => `R$${amount.toFixed(2)}`,
	},
	GBP: {
		symbol: '£',
		template: (amount) => `£${amount.toFixed(2)}`,
	},
	DKK: {
		symbol: 'kr',
		template: (amount) => `${amount.toFixed(2)}-kr.`,
	},
	NOK: {
		symbol: 'kr',
		template: (amount) => `${amount.toFixed(2)}-kr.`,
	},
	SEK: {
		symbol: 'kr',
		template: (amount) => `${amount.toFixed(2)}-kr.`,
	},
	PLN: {
		symbol: 'zł',
		template: (amount) => `${amount.toFixed(2)}zł`,
	},
	CZK: {
		symbol: 'Kč',
		template: (amount) => `${amount.toFixed(2)}Kč`,
	},
	HUF: {
		symbol: 'Ft',
		template: (amount) => `${amount.toFixed(2)}Ft`,
	},
	BGN: {
		symbol: 'лв',
		template: (amount) => `лв${amount.toFixed(2)}`,
	},
	RON: {
		symbol: 'lei',
		template: (amount) => `lei${amount.toFixed(2)}`,
	},
	MYR: {
		symbol: 'RM',
		template: (amount) => `RM${amount.toFixed(2)}`,
	},
	CHF: {
		symbol: 'Fr',
		template: (amount) => `${amount.toFixed(2)}Fr`,
	},
	INR: {
		symbol: '₹',
		template: (amount) => `₹${amount.toFixed(2)}`,
	},
	AED: {
		symbol: 'د.إ',
		template: (amount) => `${amount.toFixed(2)}د.إ`,
	},
	THB: {
		symbol: '฿',
		template: (amount) => `฿${amount.toFixed(2)}`,
	},
	COP: {
		symbol: 'CO$',
		template: (amount) => `CO$${amount.toFixed(2)}`,
	},
	ZAR: {
		symbol: 'R',
		template: (amount) => `R${amount.toFixed(2)}`,
	},
	PHP: {
		symbol: '₱',
		template: (amount) => `₱${amount.toFixed(2)}`,
	},
	IDR: {
		symbol: 'Rp',
		template: (amount) => `Rp ${amount.toFixed(2)}`,
	},
	ARS: {
		symbol: 'AR$',
		template: (amount) => `AR$${amount.toFixed(2)}`,
	},
	HRK: {
		symbol: 'kn',
		template: (amount) => `${amount.toFixed(2)} kn`,
	},
	GIP: {
		symbol: '£',
		template: (amount) => `£${amount.toFixed(2)}`,
	},
	AOA: {
		symbol: 'Kz',
		template: (amount) => `Kz${amount.toFixed(2)}`,
	},
	CVE: {
		symbol: 'CV$',
		template: (amount) => `CV$${amount.toFixed(2)}`,
	},
	TWD: {
		symbol: 'TWD',
		template: (amount) => `NT$${amount.toFixed(2)}`,
	},
	PKR: {
		symbol: 'PKR',
		template: (amount) => `Rs. ${amount.toFixed(2)}`,
	},
};
