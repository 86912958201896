import { CURRENCIES } from '@zyro-inc/site-modules/utils/ecommerce/constants';

export default function formatPrice(priceInCents, currency) {
	const convertedPrice = priceInCents * 0.01;

	if (!currency) {
		return convertedPrice;
	}

	const configItem = CURRENCIES[currency.toUpperCase()];

	return configItem ? configItem.template(convertedPrice) : `${currency}${convertedPrice.toFixed(2)}`;
}
