<template>
	<div
		class="product-list-item"
		:class="{ 'product-list-item--centered': isCentered }"
	>
		<div class="product-list-item__image-wrapper">
			<ProductImage
				v-if="image"
				:src="image"
				:alt="title"
				class="product-list-item__image"
				:is-eager="isEager"
				:width="imageMaxWidth"
				:height="imageMaxWidth"
				enable-srcset
				:is-lossless="true"
			/>
			<ProductImagePlaceholder
				v-else
				class="product-list-item__image"
			/>
		</div>
		<h6 class="product-list-item__title">
			{{ title }}
		</h6>
		<div class="product-list-item__price-wrapper">
			<template v-if="isInStock">
				<p :class="{ 'product-list-item__price': price.sale_amount }">
					{{ formatPrice(price.amount, price.currency_code) }}
				</p>
				<p v-if="price.sale_amount">
					{{ formatPrice(price.sale_amount, price.currency_code) }}
				</p>
				<p
					v-if="productType === PRODUCT_TYPE_BOOKING"
					class="product-list-item__duration"
				>
					{{ duration }}
				</p>
			</template>
			<p
				v-else
				class="product-list-item__text"
			>
				{{ translations.soldOut }}
			</p>
		</div>
	</div>
</template>

<script>
import { PRODUCT_TYPE_BOOKING } from '@zyro-inc/site-modules/constants/ecommerce';
import formatPrice from '@zyro-inc/site-modules/utils/ecommerce/priceFormatter';
import ProductImage from '@zyro-inc/site-modules/components/ecommerce/ProductImage.vue';
import ProductImagePlaceholder from '@zyro-inc/site-modules/components/ecommerce/-partials/ProductImagePlaceholder.vue';

import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		ProductImage,
		ProductImagePlaceholder,
	},

	props: {
		image: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			required: true,
		},
		price: {
			type: Object,
			required: true,
		},
		isCentered: {
			type: Boolean,
			default: false,
		},
		isEager: {
			type: Boolean,
			default: false,
		},
		imageMaxWidth: {
			type: Number,
			required: true,
		},
		duration: {
			type: String,
			required: true,
		},
		productType: {
			type: String,
			default: '',
		},
		translations: {
			type: Object,
			default: () => ({}),
		},
		isStoreQuantityTracked: {
			type: Boolean,
			default: false,
		},
		quantity: {
			type: Number,
			default: 0,
		},
	},

	data: () => ({
		PRODUCT_TYPE_BOOKING,
	}),

	computed: {
		isInStock() {
			return !this.isStoreQuantityTracked || this.quantity > 0;
		},
	},

	methods: {
		formatPrice,
	},
});
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";
@import "@zyro-inc/site-modules/scss/mixins/font-style";
@include font-style("h6", "h6", ".product-list-item");
@include font-style("body", "p", ".product-list-item");

.product-list-item {
	$this: &;

	display: flex;
	flex-direction: column;
	cursor: pointer;

	&:hover,
	&:focus {
		#{$this}__image {
			filter: brightness(0.7);
		}
	}

	&--centered {
		align-items: center;
		text-align: center;
	}

	&__image-wrapper {
		position: relative;
		display: flex;
		width: 100%;

		// variable defined on parent element
		height: var(--image-max-width);
		padding-bottom: 100%;
		overflow: hidden;
		transition: height 0.2s ease-in;
	}

	&__image {
		position: absolute;
		width: 100%;
		height: 100%;
		transition: filter 0.3s ease-in-out;
		object-fit: cover;
		object-position: center;
	}

	&__title {
		margin: 20px 0 8px;
	}

	&__price-wrapper {
		display: flex;
	}

	&__price {
		margin-right: 8px;
		opacity: 0.4;

		&#{&} {
			text-decoration: line-through;
		}
	}

	&__text {
		&#{&} {
			text-transform: uppercase;
		}

		opacity: 0.6;
	}

	&__duration {
		&::before {
			margin: 0 8px;
			content: "|";
		}
	}
}

@include site-engine-mobile {
	.product-list-item {
		&__image-wrapper {
			height: 100%;
		}
	}
}
</style>
