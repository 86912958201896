import {
	NAVIGATION_PAGE_TYPE,
	SYSTEM_LOCALE,
} from '@zyro-inc/site-modules/constants';
import { getPagePathFromId } from '@zyro-inc/site-modules/utils/page/getPagePathFromId';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';
import { getBackgroundStyles } from '@zyro-inc/site-modules/utils/getBackgroundStyles';
import { getEcwidPages } from '@zyro-inc/site-modules/utils/getters/getEcwidPages';
import { getIsLocaleWithEcommerce } from '@zyro-inc/site-modules/utils/getters/getIsLocaleWithEcommerce';
import { getOptimizedSrc } from '@zyro-inc/site-modules/utils/getSrcsets';
import { getImageSrc } from '@zyro-inc/site-modules/utils/getImageSrc';
import { FULL_WIDTH } from '@zyro-inc/site-modules/utils/getGridItemSize';

export const getHeaderProps = (siteData, siteId, {
	currentLocale,
	currentPageId,
	isLogoOptimized,

	// potentially handle via composables / inside BlockHeader:
	shoppingCartItems,
	ecwidCartItemCount,
	isOpen,
}) => {
	const {
		languages,
		meta,
	} = siteData;

	const {
		blocks,
		nav,
		pages,
		elements,
	} = languages[currentLocale];

	const {
		headerHeight,
		defaultLocale,
	} = meta;

	const {
		background,
		settings,
		fontWeight,
		fontFamily,
	} = blocks.header;

	const {
		imageOrigin,
		imagePath,
	} = background;

	const {
		cartText,
		isCartVisible,
		isLanguageSwitcherHidden,
		isSticky,
		logoPlacement,
		navigationPlacement,
		showLogo,
		styles,
		logoImageOrigin,
		logoImagePath,
	} = settings;

	const ecwidPages = getEcwidPages({
		blocks,
		pages,
	});
	const isLocaleWithEcwid = Object.keys(ecwidPages).length > 0;
	const isLocaleWithEcommerce = getIsLocaleWithEcommerce({
		blocks,
		elements,
	});

	// filter out system language,
	// attach 'href' and locale to each language
	const siteLanguages = Object.keys(languages)
		.filter((locale) => locale !== SYSTEM_LOCALE)
		.map((locale) => ({
			...languages[locale],
			href: defaultLocale === locale ? '/' : `/${locale}`,
			locale,
		})) ?? [];

	const currentLanguage = siteLanguages.find((language) => language?.locale === currentLocale);

	const headerCSSVars = {
		...objectToCssVariables(styles),
		...getBackgroundStyles(background),
	};

	const getLogoSrc = () => {
		if (!isLogoOptimized) {
			return getImageSrc(logoImageOrigin, logoImagePath, siteId);
		}

		const logoWidth = Number.parseInt(styles['logo-width'], 10);

		return getOptimizedSrc(logoImageOrigin, logoImagePath, siteId, {
			width: (window?.devicePixelRatio ?? 1) * logoWidth,
		});
	};

	const getHeaderBackgroundImageUrl = () => getOptimizedSrc(imageOrigin, imagePath, siteId, {
		width: FULL_WIDTH,
	});

	const mapNavItem = (navItem) => {
		const {
			navItemId,
			linkType,
			linkedPageId,
			isHidden,
			rel,
			href,
			target,
			name,
			subItems = [],
		} = navItem;

		return {
			navItemId,
			hasDropdown: !!subItems.length,
			isCurrent: currentPageId === linkedPageId,
			subItems: subItems.map(mapNavItem),
			target,
			name: linkType === NAVIGATION_PAGE_TYPE ? pages[linkedPageId]?.name : name,
			isHidden,
			rel,
			href: linkType === NAVIGATION_PAGE_TYPE ? getPagePathFromId({
				siteData,
				pageId: linkedPageId,
				locale: currentLocale,
			}) : href,
			locale: currentLocale,
		};
	};

	const navItems = nav.filter(({ isHidden }) => !isHidden).map(mapNavItem);

	const showCart = isCartVisible && (isLocaleWithEcwid || isLocaleWithEcommerce);

	const isLanguageSwitcherVisible = !!siteLanguages.length
		&& !isLanguageSwitcherHidden
		&& currentLocale !== SYSTEM_LOCALE;

	const getCartItemsCount = () => {
		if (isLocaleWithEcommerce) {
			return shoppingCartItems.length;
		}

		if (!isCartVisible) {
			return 0;
		}

		return ecwidCartItemCount;
	};

	const getCartPath = () => {
		if (!ecwidPages || !Object.keys(ecwidPages).length) {
			return null;
		}

		// Select first ecwid page if it exists
		const [firstEcwidPageId] = Object.keys(ecwidPages);

		const firstEcwidPagePath = getPagePathFromId({
			siteData,
			pageId: firstEcwidPageId,
			locale: currentLocale,
		});

		return `${firstEcwidPagePath}?store-page=cart`;
	};

	return {
		background,
		backgroundColor: background.color,
		backgroundImageUrl: getHeaderBackgroundImageUrl(),
		backgroundColorContrast: styles.contrastBackgroundColor,
		cartText,
		cartIconSize: styles.cartIconSize,
		cartUrl: getCartPath(),
		currentLocale,
		currentLanguage,
		height: headerHeight,
		fontFamily,
		fontWeight,
		isTransparent: background.isTransparent,
		items: navItems,
		isCartVisible: showCart,
		itemsInCart: getCartItemsCount(),
		isSticky,
		isLanguageSwitcherVisible,
		isLogoVisible: showLogo,
		isLogoOptimized,
		isOpen,
		logoHeight: styles['logo-height'],
		logoHref: currentLocale === defaultLocale ? '/' : `/${currentLocale}`,
		logoHeightMobile: styles['m-logo-height'],
		logoMaxWidth: styles['logo-width'],
		logoMaxWidthMobile: styles['m-logo-width'],
		logoObjectPosition: styles['logo-image-object-position'],
		logoObjectPositionMobile: styles['m-logo-image-object-position'],
		logoPlacement,
		logoPlacementMobile: settings['m-logoPlacement'] || logoPlacement,
		logoSrc: getLogoSrc(),
		siteLanguages,
		style: headerCSSVars,

		// TODO: Rework after Layout presets iteration
		navigationPlacement,
		navigationPlacementMobile: settings['m-navigationPlacement'] || navigationPlacement,
	};
};
